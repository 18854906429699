import axios from "axios";
import storage from "app/storage";

axios.interceptors.response.use(
  // On Success.
  (res) => {
    return res;
  },
  // On Error.
  (err) => {
    if (err.response.status == 401) {
      console.log("Token no longer valid!");
      window.location.href = "/logout";
    }
    return Promise.reject(err);
  }
);

axios.defaults.headers.common["Authorization"] =
  "Bearer " + storage.authentication.token();

export default axios;
